<template>
  <div class="modal fade " id="referralInformationUpdateModal" tabindex="-1" role="dialog"
       aria-labelledby="referralInformationUpdateModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Referral Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h5>Commission Information</h5>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Percentage on Appointment*</label>
                    <input v-model="putReferralData.commission_rate" min="0.00" step="0.001" type="number"
                           class="form-control">
                    <div class="text-danger" v-if="errors.referral.commissionRate">
                      {{ errors.referral.commissionRate }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Company Name *</label>
                    <input v-model="putReferralData.company_name" type="text" class="form-control"
                           placeholder="Enter Company Name">
                    <div class="text-danger" v-if="errors.referral.companyName">
                      {{ errors.referral.companyName }}
                    </div>
                  </div>
                </div>
              </div>
              <h5>ABN Information</h5>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>ABN *</label>
                    <input v-model="putReferralData.abn" type="text" class="form-control"
                           placeholder="Enter ABN.">
                    <div class="text-danger" v-if="errors.referral.abn">
                      {{ errors.referral.abn }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateReferralInformation">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// core packages
import {mapActions} from "vuex";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "ReferralInformationUpdateModal",
  props: ['referral'],
  emits: ['updateReferralData'],
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      id: this.referral.id,
      putReferralData: {
        commission_rate: '',
        company_name: '',
        abn: '',
      },
      errors: {
        referral: {
          commissionRate: '',
          companyName: '',
          abn: '',
        },
      },
    };
  },
  watch: {
    referral(referral) {
      this.id = referral.id;
      this.putReferralData = {
        commission_rate: referral.commission_rate,
        company_name: referral.company_name,
        abn: referral.abn,
      };
    },
  },
  methods: {
    ...mapActions({
      putReferral: 'appReferrals/putReferral',
    }),

    async emitUpdateReferralData(updateReferralData) {
      this.$emit('updateReferralData', updateReferralData);
    },

    async updateReferralInformation() {
      let dataObj = {
        id: this.id,
        data: this.putReferralData,
      };
      await this.loader(true);
      await this.putReferral(dataObj).then(async (response) => {
        await this.loader(false);
        if (response.status === 200) {
          document.querySelector('[data-target="#referralInformationUpdateModal"]').click();

          let toastObj = {
            type: 'success',
            message: 'Referral Information Updated Successfully.',
          };
          await this.showToastMessage(toastObj);
          await this.emitUpdateReferralData(this.putReferralData);
        }

        this.errors.referral.commissionRate = response.errors && response.errors.commission_rate ? response.errors.commission_rate[0] : '';
        this.errors.referral.companyName = response.errors && response.errors.company_name ? response.errors.company_name[0] : '';
        this.errors.referral.abn = response.errors && response.errors.abn ? response.errors.abn[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    }
  },
}
</script>